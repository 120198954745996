<template>
  <div class="home">
    <div class="bodydiv">
      <aside>
        <div class="logotitle" @click="backhome('backhomepage')">
          <!-- <i class="el-icon-cherry"></i> -->
          <img :src="home_logo" alt="" />
          <!-- <span>EasyNoteAI</span> -->
        </div>
        <ul>
          <li @click="backhome('backhomepage')">
            <!-- <i class="el-icon-s-home"></i> -->
            Dashboard
          </li>
          <li :class="{ liactiveis: noteasidekey == 'notes' }" @click="gopageurl('notes')">
            <!-- <i class="el-icon-edit-outline"></i> -->
            Notes
          </li>
          <li :class="{ liactiveis: noteasidekey == 'mindmap' }" @click="gopageurl('mindmap')">
            <!-- <i class="el-icon-edit-outline"></i> -->
            Mind Map
            <span v-if="notclick == 0" class="new">New</span>
          </li>
          <li v-if="noteobj?.filetype !== 'record'" :class="{ liactiveis: noteasidekey == 'chatbot' }"
            @click="gopageurl('chatbot')">
            <!-- <i class="el-icon-chat-square"></i> -->
            Chat Bot
          </li>
          <li :class="{ liactiveis: noteasidekey == 'flashcards' }" @click="gopageurl('flashcards')">
            <!-- <i class="el-icon-files"></i> -->
            Flashcards
          </li>
          <li :class="{ liactiveis: noteasidekey == 'quiz' }" @click="gopageurl('quiz')">
            <!-- <i class="el-icon-c-scale-to-original"></i> -->
            Quiz
          </li>
          <li
            v-if="noteobj?.filetype !== 'record' || (noteobj?.filetype == 'record' && !noteobj?.live_transcript?.text_objs)"
            :class="{ liactiveis: noteasidekey == 'transcript' }" @click="gopageurl('transcript')">
            <!-- <i class="el-icon-document"></i> -->
            Transcript
          </li>
          <li :class="{ liactiveis: noteasidekey == 'settings' }" @click="gopageurl('settings')">
            <!-- <i class="el-icon-s-tools"></i> -->
            Settings
          </li>
        </ul>
        <div class="gopaypage" @click="gopaypage">
          <!-- <i class="el-icon-star-on"></i>  -->
          Upgrade to Premium
        </div>
        <div class="acount acount_notes">
          <div class="signin">
            <!-- <i class="el-icon-user-solid"></i> -->
            <img :src="userimg" alt="" />
            <span>{{ email }}</span>
          </div>
        </div>
      </aside>
      <main class="mainview">
        <div class="titlebox" v-if="noteobj?.notename">
          <div class="titlebox-l">
            <div class="titletxt">
              {{ noteobj?.notename }}
            </div>
            <div class="title_date">{{ formatDateTime(noteobj?.createtime) }}</div>
          </div>
          <div class="filedownload"
            v-if="noteobj?.filename && (noteobj.filetype == 'record' || noteobj.filetype == 'audio') && isdownloadable">
            <div class="download" @click="saveaudio(noteobj)">
              <!-- <span>Save Audio Locally</span> -->
              <span>{{ noteobj?.filename }}</span>
            </div>
          </div>
          <div class="export">
            <div class="copyas" @click="noteshare()">Share my Notes</div>
          </div>

        </div>
        <div class="notes-container" v-if="noteasidekey == 'notes'" :class="{ 'with-translate': showTranslate }">
          <notenotes v-bind:noteobj="noteobj" @tryagain="tryagain" @toggleTranslate="toggleTranslate" />
          <TranslateToggle v-if="showTranslate" :noteobj="noteobj" ref="translateToggle" @setnoteobj="setnoteobj" />
        </div>
        <notemindmap v-bind:noteobj="noteobj" v-if="noteasidekey == 'mindmap'" />
        <notechatbot v-bind:noteobj="noteobj" v-if="noteasidekey == 'chatbot'" />
        <noteflashcards v-bind:noteobj="noteobj" v-if="noteasidekey == 'flashcards'" @setflashcards="setflashcards" />
        <notequiz v-bind:questionsI="questionsI" v-bind:noteobj="noteobj" v-if="noteasidekey == 'quiz'"
          @setquiz="setquiz" />
        <notetranscript v-bind:transcript="transcript" v-if="noteasidekey == 'transcript'" />
        <notesetting v-bind:noteobj="noteobj" @openchangetitle="openchangetitle" @deletenote="deletenote"
          v-if="noteasidekey == 'settings'" />
      </main>
    </div>
    <notechangetitle @savenotes="savenotes" ref="pchangetitle" />
  </div>
</template>

<script>
import notenotes from "@/views/pages/components/note_notes.vue";
import notechatbot from "@/views/pages/components/note_chatbot.vue";
import noteflashcards from "@/views/pages/components/note_flashcards.vue";
import notequiz from "@/views/pages/components/note_quiz.vue";
import notetranscript from "@/views/pages/components/note_transcript.vue";
import notesetting from "@/views/pages/components/note_settting.vue";
import notechangetitle from "@/views/pages/components/popups/popup_changetitle.vue";
import notemindmap from "@/views/pages/components/note_mindmap.vue";
import TranslateToggle from "@/views/pages/components/translate_toggle.vue";
export default {
  components: {
    notenotes,
    notechatbot,
    noteflashcards,
    notequiz,
    notetranscript,
    notesetting,
    notechangetitle,
    notemindmap,
    TranslateToggle
  },
  name: "HomeIndexpage",
  data() {
    return {
      noteasidekey: "notes",
      home_logo: require("../assets/images/index/logo.png"),
      userimg: require("../assets/images/home/userimg.png"),

      showpaybtn: false,
      noteobj: { status: -1 },

      questionsI: [],
      transcript: {},

      timer: null,

      email: "",
      notclick: 0,
      isdownloadable: false,
      showTranslate: false,
      targetLanguage: 'zh',
      translatedContent: '',
    };
  },

  watch: {
    noteasidekey() {
      // 在 noteasidekey 变化时，将滚动条位置重置到最顶部
      // console.log("newVal", newVal);
      this.$nextTick(() => {
        document.querySelector(".mainview").scrollTop = 0;
      });
    },
    '$route.params': 'handleRouteParams',
    noteobj(newVal) {
      this.isdownloadable = newVal?.createtime > '2024-12-15';
    }
  },
  mounted() {
    this.handleRouteParams();
    this.email = this.$cookies.get("email");
    this.notclick = this.$cookies.get("notclick") || 0;
  },
  methods: {
    setflashcards(falshcard) {
      this.noteobj.note_cards = falshcard;
    },
    setquiz(quiz) {
      this.noteobj.note_quiz = quiz;
    },
    async handleRouteParams() {
      const { noteid, mode } = this.$route.params;

      console.log("noteid", this.$route.params);


      let this_noteid = noteid;
      if (!this_noteid) {
        this_noteid = this.$route.params.note?.noteid;
      }
      if (!this_noteid && this.$route.params?.pagekey) {
        this_noteid = this.getNoteIdFromCookie();
      }
      if (!this_noteid) {
        // 跳转到home页面
        this.$router.push({
          name: "Homeindex",
        });
        return;
      }

      this.getnotes(this_noteid);
      this.setupTimer(this_noteid);
      this.saveNoteIdToCookie(this_noteid); // 将 noteid 存到 cookie

      const pagekey = this.$route.params.pagekey;
      if (pagekey) {
        // 根据 URL 中的 pagekey 展示相应组件
        this.chooseaside(pagekey);
        return;
      }
      if (mode) {
        this.chooseaside(mode);
      }

    },
    setupTimer(noteid) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getnotes(noteid);
      }, 2000);
    },
    saveNoteIdToCookie(noteid) {
      this.$cookies.set("noteid", noteid, { path: "/" });
    },
    setnoteobj(noteobj) {
      this.noteobj = noteobj;
    },
    removeNoteIdFromCookie() {
      this.$cookies.remove("noteid");
    },
    getNoteIdFromCookie() {
      return this.$cookies.get("noteid");
    },
    backhome(isget) {
      this.$router.push({
        name: "Homeindex",
        params: { dontgetmynotes: isget },
      });
    },
    openchangetitle() {
      this.$refs.pchangetitle.openModal();
    },
    gopaypage() {
      this.$router.push({
        name: "IndexPage", // 目标路由的名称
        params: { pagekey: "price" }, // 通过 params 传递参数
        // query: { category: "electronics" }, // 通过 query 传递参数
      });
    },
    async getnotes(noteid, status = 0) {
      if (status == 10) {
        this.noteobj.status = 0;
      }
      try {
        const respne = await this.$axios.post("api/getnotes/", {
          noteid: noteid,
        });
        const redata = respne.data;
        if (redata.code == 200) {
          this.noteobj = redata.noteobj;
          this.noteobj.status = 1;
          clearInterval(this.timer);
        }
        if (redata.code == 201) {
          this.$message({
            center: true,
            message: "The note does not exist",
            type: "warning",
          });
          clearInterval(this.timer);
        }
        if (redata.code == 202) {
          this.noteobj.status = 0;
          this.noteobj.filetype = redata.noteobj.filetype;
        }
        if (redata.code == 203) {
          this.noteobj = redata.noteobj;
          this.$message({
            center: true,
            message: "Note generation failed",
            type: "error",
          });

          clearInterval(this.timer);
        }
      } catch (error) {
        clearInterval(this.timer);
        console.log("error", error);
      }
    },
    async tryagain() {
      try {
        // console.log("tryagain", this.noteobj);
        const noteid = this.noteobj?.noteid;
        const res = await this.$axios.post("api/renotes_ask/", {
          noteid: noteid
        });
        const redata = res.data;
        if (redata.code == 200) {
          this.getnotes(redata.noteid);
          this.setupTimer(noteid);
        } else if (redata.code == 201) {
          this.$message({
            center: true,
            message: redata.messages,
            type: "warning",
          });
        }


      } catch (error) {
        console.error(error);
      }
    },
    savenotes(notetitle) {
      // console.log("修改笔记标题notetitle", notetitle);
      this.$set(this.noteobj, "notename", notetitle);
      // 保存新笔记名称到数据库
      this.$axios.post("api/updatenotename/", {
        noteid: this.noteobj.noteid,
        notename: notetitle,
      });
    },
    async deletenote() {
      const noteid = this.noteobj.noteid;
      const subid = this.$cookies.get("subid");
      // console.log("删除笔记", noteid);
      await this.$axios.post("api/deletenote/", {
        noteid: noteid,
        subid: subid,
      });

      this.backhome("get");
    },
    gopageurl(key) {
      if (key == "mindmap") {
        this.notclick = 1;
        this.$cookies.set("notclick", 1, { expires: 30 });
      }
      this.$router.push({
        name: "notesPage_key", // 目标路由的名称
        params: { pagekey: key }, // 通过 params 传递参数
        // query: { category: "electronics" }, // 通过 query 传递参数
      });
    },
    chooseaside(key) {
      this.noteasidekey = key;
      switch (key) {
        case "notes":
          break;
        case "chatBot":
          break;
        case "flashcards":
          break;
        case "quiz":
          this.questionsI = this.noteobj?.note_quiz;
          for (let i = 0; i < this.questionsI?.length; i++) {
            // ischooes: false,
            // chooese: -1,
            this.$set(this.questionsI[i], "ischooes", false);
            this.$set(this.questionsI[i], "chooese", -1);
          }
          break;
        case "transcript":

          if (this.noteobj.filetype == 'record') {
            if (this.noteobj?.live_transcript?.text_objs) {
              this.transcript["value"] = this.noteobj?.live_transcript;
              this.transcript["notetoken"] = this.noteobj?.notetoken;
            } else {
              this.transcript["value"] = this.noteobj?.live_transcript;
              this.transcript["notetoken"] = this.noteobj?.notetoken;
            }
          } else {
            this.transcript["value"] = this.noteobj?.note_transcript;
            this.transcript["notetoken"] = this.noteobj?.notetoken;

          }
          this.transcript["type"] = this.noteobj?.filetype;

          break;
        case "settings":
          break;
        default:
          break;
      }
    },
    formatDateTime(dateTime) {
      if (!dateTime) return "";
      const date = new Date(dateTime);
      const options = {
        weekday: "long", // 添加星期几
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      return date.toLocaleString("en-US", options);
    },
    async saveaudio(noteobj) {

      const fileKey = noteobj.filepath;
      const filename = noteobj.filename;

      try {
        this.$message.info('Starting download...');

        // 获取下载链接
        const { data } = await this.$axios.post('/api/get_download_url/', {
          noteid: noteobj.noteid,
          fileKey: fileKey
        });

        if (!data.downloadUrl) {
          throw new Error('Failed to get download URL');
        }

        // 使用 fetch 获取文件内容
        const response = await fetch(data.downloadUrl);
        if (!response.ok) {
          throw new Error('Download failed');
        }

        // 获取文件 blob
        const blob = await response.blob();

        // 创建 blob URL
        const blobUrl = window.URL.createObjectURL(blob);

        // 创建下载链接
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename; // 设置下载文件名
        link.style.display = 'none';

        // 添加到文档并触发点击
        document.body.appendChild(link);
        link.click();

        // 清理
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);

        this.$message.success('Download completed!');

      } catch (error) {
        console.error('Download failed:', error);
        this.$message.error(`Download failed: ${error.message}`);
      }


      // try {
      //   const response = await this.$axios({
      //     url: `/api/download/${noteid}/`,
      //     method: 'GET',
      //     responseType: 'blob',
      //   });
      //   const contentType = response.headers['content-type'];
      //   const contentDisposition = response.headers['content-disposition'];
      //   let filename = 'download';
      //   if (contentDisposition) {
      //     const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      //     const matches = filenameRegex.exec(contentDisposition);
      //     if (matches != null && matches[1]) {
      //       filename = matches[1].replace(/['"]/g, '');
      //     }
      //   }
      //   console.log('filename', filename, contentType);
      //   const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute('download', filename);
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // } catch (error) {
      //   console.error('下载文件时出错:', error);
      //   this.$message({ message: "File not found", type: "error" });
      // }
    },
    toggleTranslate(isopen = null) {
      // console.log("toggleTranslate", this.showTranslate , this.noteobj);
      if (isopen == null) {
        this.showTranslate = !this.showTranslate;
      } else {
        this.showTranslate = isopen;
      }
    },
    noteshare() {
      console.log("noteshare", this.noteobj);
      const shareurl = window.location.origin + '/share?t=' + this.noteobj.notetoken;
      this.copyToClipboard(shareurl);
    },
    copyToClipboard(text) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(text).then(() => {
          this.$message({ message: "Copied to clipboard", type: "success" });
        }).catch((err) => {
          console.error('Could not copy text: ', err);
        });
      } else {
        // 使用旧的方法复制到剪贴板
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          this.$message({ message: "Copied to clipboard", type: "success" });
        } catch (err) {
          console.error('Could not copy text: ', err);
        }
        document.body.removeChild(textArea);
      }
    },
  },
  beforeDestroy() {
    // 组件销毁时清理定时任务
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.removeNoteIdFromCookie();
  },
};
</script>

<style lang="less" scoped>
@import "../assets/styles/home.less";

.notes-container {
  display: flex;
  transition: all 0.3s ease;
  height: calc(100% - 110px);
  /* 减去标题栏高度 */
  position: relative;
}


@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .notes-container.with-translate {
    flex-direction: column;
  }

  .notes-container.with-translate>*:first-child,
  .translate-panel {
    width: 100%;
  }
}
</style>
