<template>
  <div class="lawful-interception-guide">
    <div class="progress-container" v-show="notes.iscreating == -1">
      <h1>Loading...</h1>
    </div>
    <div class="progress-container" v-show="notes.iscreating == 0">
      <img class="loadingimg" :src="loading_img" alt="" />
      <h1>We're Creating Your Notes...</h1>
      <p class="progress-text">{{ progress }}% Completed</p>
      <div class="progress-bar">
        <div class="progress" :style="{ width: progress + '%' }"></div>
      </div>
      <p v-if="noteobj.filetype == 'audio'" class="uploadtishi"><i class="el-icon-info"></i>Estimated processing time:
        5-30 minutes based on audio length. Please wait
        patiently. Do not refresh or close the page.</p>
    </div>
    <div class="progress-container" v-show="notes.iscreating == 3">
      <img class="loadingimg" :src="error_img" alt="" />
      <h1 v-if="notes.taskcount <= 1">Note generation failed, please try again later.</h1>
      <h1 v-else>It seems there's a problem with the file. <br>Please try a new one.</h1>
      <div class="notebtnbox">
        <button v-if="notes.taskcount <= 1" class="resert" @click="tryagain">Try again</button>
        <button v-if="noteobj.filetype == 'audio'" class="save" @click="saveaudio">Save audio locally</button>
        <span v-if="false"> <i class="el-icon-warning"></i>The recording file will be deleted in
          24 hours. Please save it to your local device immediately.</span>
      </div>
    </div>
    <div class="progress-container" v-show="notes.iscreating == 4">
      <img class="loadingimg" :src="error_img" alt="" />
      <h1>The recording file has been automatically <br>deleted as it has exceeded the 24-hour saving period.</h1>
    </div>
    <div v-show="notes.iscreating == 1">
      <el-dropdown class="export"
        v-if="noteobj.filetype != 'record' || (noteobj?.filetype == 'record' && !noteobj?.live_transcript?.text_objs)"
        @command="handleCommand">
        <span class="el-dropdown-link">
          Copy as<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="copymarkdown" icon="el-icon-c-scale-to-original">Copy as
            Markdown</el-dropdown-item>
          <el-dropdown-item command="copytext" icon="el-icon-tickets">Copy as
            TXT</el-dropdown-item>
          <el-dropdown-item command="downloadaspdf" icon="el-icon-download">Download as
            PDF</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-button @click="downloadNotes" class="el-icon-upload2 export">Export</el-button> -->
      <div class="translate-toggle" v-if="showTranslateButton" @click="toggleTranslate(null)">
        <div class="tooltip" v-if="!showTranslate">Click here to <br> translate notes</div>
        <i v-if="showTranslate" class="el-icon-d-arrow-right"></i>
        <i v-else class="el-icon-d-arrow-left"></i>
      </div>
      <div class="youtubebox" v-if="noteobj.filetype == 'youtube' && !showTranslate">
        <YoutubePlayer v-if="noteobj?.filelinkpath" :url="noteobj?.filelinkpath" />
      </div>
      <div ref="contentToDownload" class="markdown-content"
        v-if="notes.mk_notes && (noteobj.filetype != 'record' || (noteobj?.filetype == 'record' && !noteobj?.live_transcript?.text_objs))"
        v-html="renderedNotes"></div>
      <div class="copyasbox" v-if="noteobj.filetype == 'record' && noteobj?.live_transcript?.text_objs">
        <div class="left">
          <div>Transcript</div>
          <!-- <div class="copyas" @click="copytranscript('text')">Copy as</div> -->
          <el-dropdown class="copyas" @command="handleCommand">
            <span class="el-dropdown-link">
              Copy as<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="copytext-rr" icon="el-icon-tickets">Copy as
                TXT</el-dropdown-item>
              <el-dropdown-item command="downloadaspdf-rr" icon="el-icon-download">Download as
                PDF</el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="right">
          <div>Notes</div>
          <!-- <div class="copyas" @click="copytranscript('part')">Copy as</div> -->
          <el-dropdown class="copyas" @command="handleCommand">
            <span class="el-dropdown-link">
              Copy as<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="copymarkdown-lr" icon="el-icon-c-scale-to-original">Copy as
                Markdown</el-dropdown-item>
              <el-dropdown-item command="copytext-lr" icon="el-icon-tickets">Copy as
                TXT</el-dropdown-item>
              <el-dropdown-item command="downloadaspdf-lr" icon="el-icon-download">Download as
                PDF</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>

      </div>
      <div class="alltextbox" v-if="noteobj.filetype == 'record' && noteobj?.live_transcript?.text_objs">
        <div class="left_text left_tb" ref="left_text">
          <div class="textbox" v-for="(textobj, i) in transcript?.text_objs" :key="i">
            <h5>{{ textobj.time }}</h5>
            <div class="yuantext">{{ textobj.text }}</div>
            <div class="translatetext"> {{ textobj.trans }}</div>
          </div>
        </div>
        <div class="resizer" ref="resizer"></div>
        <div class="left_text right_tb" ref="right_tb">
          <div class="textbox" v-for="(textobj, i) in transcript?.part_objs" :key="i">
            <h5>{{ textobj.time }}</h5>
            <div class="yuantext markdown-content-noh" v-html="textobj.mk_notes"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import YoutubePlayer from '@/components/YoutubePlayer.vue'
export default {
  components: {
    YoutubePlayer
  },
  props: {
    noteobj: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    noteobj: {
      handler(newobject) {
        this.iscreating = this.noteobj.status;

        this.notes["iscreating"] = newobject.status;
        this.notes["taskcount"] = newobject.task_count;
        // this.notes["iscreating"] = 0;
        this.notes["mk_notes"] = newobject.note_text;
        if (newobject.filetype == 'record') {
          this.transcript = newobject.live_transcript;
          this.transcript?.part_objs.forEach((item) => {
            item['mk_notes'] = this.md.render(item.notes);
          });
        }
        const translatingNote = JSON.parse(this.$cookies.get('translating_note') || '{}')
        if ((newobject?.note_translate || translatingNote.noteid == newobject.noteid) && this.notes.iscreating == 1) {
          this.toggleTranslate(true)
        }

        this.$nextTick(() => {
          this.initResizer();
        });
      },
      deep: true, // 添加深度监听
    },
  },
  mounted() {
    this.notes["iscreating"] = this.noteobj.status;
    this.notes["mk_notes"] = this.noteobj.note_text;
    this.notes["type"] = this.noteobj.progress;
    this.notes["taskcount"] = this.noteobj.task_count;
    if (this.noteobj.filetype == 'record') {
      this.transcript = this.noteobj.live_transcript;
      this.transcript?.part_objs.forEach((item) => {
        item['mk_notes'] = this.md.render(item.notes);
      });

    }
    this.$nextTick(() => {
      if (this.noteobj.filetype === 'record') {
        this.initResizer();
      }
    });
    this.setprogress();
  },
  computed: {
    showTranslateButton() {
      return this.noteobj?.filetype !== 'record'
    },
    renderedNotes() {
      if (!this.notes || typeof this.notes.mk_notes !== "string") {
        return ""; // 或者返回一个默认消息，如 "No notes available"
      }
      return this.md.render(this.notes.mk_notes);
    },
  },
  data() {
    return {
      loading_img: require("@/assets/images/noteindex/loading_img1.gif"),
      error_img: require("@/assets/images/noteindex/error_note.png"),

      interfaces: [
        {
          name: "HI1",
          description:
            "Handover interface between the Lawful Interception Gateway (LIG) and the Monitoring Center (MC)",
        },
        {
          name: "HI2",
          description:
            "Handover interface between the Network Element (NE) and the Lawful Interception Gateway (LIG)",
        },
        {
          name: "HI3",
          description:
            "Handover interface between the Monitoring Center (MC) and the Law Enforcement Agency (LEA)",
        },
      ],
      definitions: [
        '"Lawful Interception: the interception of telecommunications traffic by authorized law enforcement agencies or other entities, with the purpose of gathering evidence or preventing harm."',
        '"Handover Interface: an interface that enables the transfer of intercepted telecommunications traffic between different entities in the lawful interception architecture."',
      ],
      md: new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
        highlight: function (str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(lang, str).value;
            } catch (__) {
              console.log("error", str, lang);
            }
          }
          return ""; // 使用默认的转义
        },
      }),
      progress: 0,
      iscreating: -1,
      timer: null,
      timer_quick: null,

      transcript: {},

      notes: {
        mk_notes: "",
        iscreating: 0,
      },

      showTranslate: false,
    };
  },
  methods: {
    toggleTranslate(isopen = null) {
      if (isopen == null) {
        this.showTranslate = !this.showTranslate;
      } else {
        this.showTranslate = isopen;
      }
      this.$emit("toggleTranslate", isopen);
    },
    handleCommand(command) {
      switch (command) {
        case "copymarkdown":
          this.copymarkdown();
          break;
        case "copymarkdown-lr":
          this.copytranscript('part');
          break;
        case "copytext":
          this.copytext();
          break;
        case "copytext-lr":
          this.copytranscript('part-text');
          break;
        case "copytext-rr":
          this.copytranscript('text');
          break;
        case "downloadaspdf":
          this.downloadaspdf('contentToDownload');
          break;
        case "downloadaspdf-rr":
          this.downloadaspdf('left_text');
          break;
        case "downloadaspdf-lr":
          this.downloadaspdf('right_tb');
          break;
        case "noteshare":
          this.noteshare();
          break;
        default:
          break;
      }
    },
    copytranscript(what) {
      let copyContent = '';
      let htmlContent = '';
      switch (what) {
        case 'part':
          copyContent = this.transcript?.part_objs
            .map((item) => item.notes)
            .join('\n');
          break;
        case 'part-text':
          htmlContent = this.transcript?.part_objs
            .map((item) => item.mk_notes)
            .join('');
          console.log("htmlContent", htmlContent);
          copyContent = this.htmlToText(htmlContent);
          break;
        case 'text':
          copyContent = this.transcript?.text_objs
            .map((item) => item.text)
            .join('\n');
          break;
      }

      this.copyToClipboard(copyContent);


    },
    copymarkdown() {
      console.log("copymarkdown", this.notes);
      const markdownContent = this.notes.mk_notes;
      this.copyToClipboard(markdownContent);
    },
    copytext() {
      const textContent = this.htmlToText(this.renderedNotes);
      this.copyToClipboard(textContent);
    },
    downloadaspdf(resobj) {
      // this.generateA4PaginatedPdf();
      this.printPdf(resobj);
    },
    async printPdf(resobj) {
      try {
        // 获取打印容器
        const printContainer = this.$refs[resobj];
        if (!printContainer || !printContainer.innerHTML.trim()) {
          throw new Error('No content to print');
        }

        // 创建一个隐藏的 iframe
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.top = '-9999px';
        iframe.style.left = '-9999px';
        document.body.appendChild(iframe);

        // 将打印容器的内容复制到 iframe 中
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        iframeDocument.open();
        iframeDocument.write(`
      <html>
        <head>
          <title>打印内容</title>
          <style>
            /* 添加你需要的样式 */
            body {
              font-family: Arial, sans-serif;
            }
            .print-container {
              padding: 20px;
            }
            ${this.getAdditionalStyles()} /* 获取额外需要的样式 */
          </style>
        </head>
        <body>
          ${printContainer.innerHTML}
        </body>
      </html>
    `);
        iframeDocument.close();

        // 等待 iframe 加载完成
        await new Promise((resolve) => {
          iframe.onload = resolve;
        });

        // 等待一小段时间，确保所有内容都已渲染
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // 调用浏览器的打印功能
        iframe.contentWindow.print();

        // 移除 iframe
        document.body.removeChild(iframe);
      } catch (error) {
        console.error('PDF打印失败:', error);
        alert('PDF打印失败，请稍后重试或联系支持人员。');
      }
    },
    // 获取额外需要的样式
    getAdditionalStyles() {
      // 这里可以返回任何额外需要的CSS
      return `
    /* 额外的CSS样式 */
    .custom-class {
      color: #333;
    }
  `;
    },
    async generateA4PaginatedPdf() {
      const content = this.$refs.contentToDownload;  // 获取需要下载的内容
      if (!content) {
        console.error('未找到需要下载的内容');
        return;
      }

      // 定义 A4 页面的尺寸（单位：像素，96DPI下）
      const A4_WIDTH = 595;
      const A4_HEIGHT = 842;
      const contentWidth = content.scrollWidth; // 容器的宽度
      const contentHeight = content.scrollHeight; // 容器的高度

      let pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
      });

      // 当前已经渲染的高度
      let renderedHeight = 0;

      while (renderedHeight < contentHeight) {
        // 使用 html2canvas 截取当前页面的内容
        const canvas = await html2canvas(content, {
          width: contentWidth,  // 按照容器的宽度截取
          height: Math.min(A4_HEIGHT, contentHeight - renderedHeight),  // 截取剩余高度，最多 A4 高度
          x: 0,
          y: renderedHeight,  // 从当前已经渲染的高度往下截取
          scrollY: 0,  // 不让页面滚动
        });

        const imgData = canvas.toDataURL('image/jpeg', 1.0);

        // 计算图像在 PDF 中的宽高比例
        const imgWidth = A4_WIDTH;
        const imgHeight = (canvas.height * A4_WIDTH) / canvas.width;

        if (renderedHeight > 0) {
          pdf.addPage();  // 添加新的一页
        }
        pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);  // 添加图片到PDF

        renderedHeight += canvas.height;  // 更新渲染过的高度
      }

      // 下载生成的 PDF 文件
      pdf.save('generated_content.pdf');
    },
    async saveaudio() {

      const fileKey = this.noteobj.filepath;
      const filename = this.noteobj.filename;

      try {
        this.$message.info('Starting download...');

        // 获取下载链接
        const { data } = await this.$axios.post('/api/get_download_url/', {
          fileKey: fileKey
        });

        if (!data.downloadUrl) {
          throw new Error('Failed to get download URL');
        }

        // 使用 fetch 获取文件内容
        const response = await fetch(data.downloadUrl);
        if (!response.ok) {
          throw new Error('Download failed');
        }

        // 获取文件 blob
        const blob = await response.blob();

        // 创建 blob URL
        const blobUrl = window.URL.createObjectURL(blob);

        // 创建下载链接
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename; // 设置下载文件名
        link.style.display = 'none';

        // 添加到文档并触发点击
        document.body.appendChild(link);
        link.click();

        // 清理
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);

        this.$message.success('Download completed!');

      } catch (error) {
        console.error('Download failed:', error);
        this.$message.error(`Download failed: ${error.message}`);
      }
      // const noteid = this.noteobj.noteid; // 替换实际的文件名
      // try {
      //   const response = await this.$axios({
      //     url: `/api/download/${noteid}/`,
      //     method: 'GET',
      //     responseType: 'blob',
      //   });
      //   const contentType = response.headers['content-type'];
      //   const contentDisposition = response.headers['content-disposition'];
      //   let filename = 'download';
      //   if (contentDisposition) {
      //     const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      //     const matches = filenameRegex.exec(contentDisposition);
      //     if (matches != null && matches[1]) {
      //       filename = matches[1].replace(/['"]/g, '');
      //     }
      //   }
      //   console.log('filename', filename, contentType);
      //   const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute('download', filename);
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // } catch (error) {
      //   console.error('下载文件时出错:', error);
      //   this.$message({ message: "File not found", type: "error" });
      // }
    },
    tryagain() {
      this.$emit("tryagain");
    },
    noteshare() {
      console.log("noteshare", this.noteobj);
      const shareurl = window.location.origin + '/share?t=' + this.noteobj.notetoken;
      this.copyToClipboard(shareurl); // 复制到剪贴板
    },
    downloadNotes() {
      // console.log("downloadNotes",this.noteobj);
      const textContent = this.htmlToText(this.renderedNotes);
      const blob = new Blob([textContent], { type: 'text/plain;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'notes.txt';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    htmlToText(html) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || '';
    },
    copyToClipboard(text) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(text).then(() => {
          this.$message({ message: "Copied to clipboard", type: "success" });
        }).catch((err) => {
          console.error('Could not copy text: ', err);
        });
      } else {
        // 使用旧的方法复制到剪贴板
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          this.$message({ message: "Copied to clipboard", type: "success" });
        } catch (err) {
          console.error('Could not copy text: ', err);
        }
        document.body.removeChild(textArea);
      }
    },
    setprogress() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        const randomnums = Math.floor(Math.random() * 4) + 1;
        this.progress += randomnums;

        if (this.iscreating == 1) {
          this.progress = 100;
        }
        if (this.progress >= 99) {
          // 停止
          this.progress = 99;
          clearInterval(this.timer);
        }
      }, 3000);
    },
    initResizer() {
      const resizer = this.$refs.resizer;
      const leftPane = this.$refs.left_text;
      const rightPane = this.$refs.right_tb;
      const container = this.$el.querySelector('.alltextbox');

      if (!resizer || !leftPane || !rightPane || !container) {
        return;
      }

      // // 恢复之前保存的宽度
      // const savedWidth = localStorage.getItem('leftPaneWidth') || '60%';
      // if (savedWidth) {
      //   leftPane.style.width = savedWidth;
      //   // 更新拖动条位置，考虑padding和gap
      //   const leftWidth = parseFloat(savedWidth);
      //   console.log("leftWidth", leftWidth);
      //   // resizer.style.left = `calc(${leftWidth+2}%)`;
      // }

      let x = 0;
      let leftWidth = 0;

      const mouseDownHandler = (e) => {
        x = e.clientX;
        leftWidth = leftPane.getBoundingClientRect().width;
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
        resizer.style.transition = 'none'; // 拖动时禁用过渡效果
      };

      const mouseMoveHandler = (e) => {
        const dx = e.clientX - x;
        const containerWidth = container.getBoundingClientRect().width;
        const minWidth = 100; // 最小宽度
        const maxWidth = containerWidth - 100; // 最大宽度

        let newLeftWidth = leftWidth + dx;
        newLeftWidth = Math.max(minWidth, Math.min(maxWidth, newLeftWidth));

        const newLeftWidthPercent = (newLeftWidth / containerWidth) * 100;
        leftPane.style.width = `${newLeftWidthPercent}%`;

        // 更新拖动条位置，确保在两个区域之间
        // resizer.style.left = `calc(${newLeftWidthPercent+2}%)`;
      };

      const mouseUpHandler = () => {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
        resizer.style.transition = 'left 0.2s'; // 恢复过渡效果
        localStorage.setItem('leftPaneWidth', leftPane.style.width);
      };

      resizer.addEventListener('mousedown', mouseDownHandler);
    }
  },
};
</script>

<style lang="less">
.lawful-interception-guide {
  color: #ffffff;
  font-family: Arial, sans-serif;
  position: relative;
  flex: 1;
  max-height: calc(100vh - 130px);
  overflow: auto;

  .export {
    position: absolute;
    right: 2.5vw;
    top: 0;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    border: none;
    font-size: 1vw;
    padding: 0.4vw 1vw;
    cursor: pointer;
    border-radius: 0.5vw;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  .translate-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
    transition: all 0.3s ease;
    position: absolute;
    top: 10px;
    right: 0.7vw;

    .tooltip {
      position: absolute;
      right: calc(100% - 10px);
      top: calc(50% + 50px);
      transform: translateY(-50%);
      background: rgba(255, 255, 255, 0.9);
      color: #333;
      padding: 8px 12px;
      border-radius: 6px;
      font-size: 14px;
      white-space: nowrap;
      margin-right: 10px;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
      font-weight: 600;

      &::after {
        content: '';
        position: absolute;
        right: -10px;
        top: -8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 10px 10px; // 改变边框宽度创建斜向箭头
        border-color: transparent transparent rgba(255, 255, 255, 0.9) transparent;
        transform: rotate(-75deg); // 旋转箭头使其指向右上
      }
    }

    i {
      font-weight: 600;
      font-size: 20px;
    }
  }

  .translate-toggle:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .progress-container {
    color: white;
    text-align: center;
    padding-top: 20vh;

    h1 {
      margin: 0;
      font-size: 2vw;
      margin-bottom: 10px;
    }

    .loadingimg {
      width: 6.5vw;
    }

    .uploadtishi {
      padding: 2vw 10%;

      i {
        color: rgb(212, 162, 97);
        margin-right: 0.5vw;
      }
    }

    .notebtnbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 3vw;

      button {
        border: none;
        width: 12vw;
        padding: 0.8vw 0;
        margin-bottom: 1vw;
        border-radius: 5px;
        font-size: 1vw;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          opacity: 0.8;
        }
      }

      .resert {
        background-color: rgb(22 163 74);
        color: #fff;
      }

      .save {
        background-color: #fff
      }

      span {
        color: red;
        font-size: 1vw;
      }

    }

    .progress-bar {
      background-color: #383838;
      border-radius: 10px;
      height: 20px;
      margin: 1vw 2.2vw;
      overflow: hidden;

      .progress {
        background: rgb(108, 92, 231);
        height: 100%;
        transition: width 0.5s ease-in-out;
      }
    }

    .progress-text {
      font-size: 16px;
    }
  }

  .copyasbox {
    display: flex;
    padding: 0 0.5vw;

    .left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 22px;

      div {
        margin-left: 2vw;
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 22px;
    }

    .copyas {
      border: 1px solid #bbb;
      color: #bbb;
      padding: 5px 10px;
      margin-right: 20px;
      cursor: pointer;
      display: inline-block;
      border-radius: 10px;
    }
  }


  .alltextbox {
    padding: 0.5vw 2vw;
    border-radius: 5px;
    display: flex;
    // position: relative;
    gap: 10px; // 添加固定间距

    .left_text {
      flex: none;
      width: calc(50% - 40px);
      background-color: rgba(255, 255, 255, 0.1);
      height: calc(100vh - 220px);
      overflow: auto;
      padding: 10px;
      margin-right: 0; // 移除原来的margin
    }

    .right_tb {
      flex: 1;
      margin-left: 0; // 确保没有额外的margin
    }

    .resizer {
      // position: absolute;
      width: 0px; // 增加宽度使其更容易拖动
      height: calc(100vh - 200px);
      border: 1px solid rgba(255, 255, 255, 0.2);

      cursor: col-resize;
      user-select: none;
      z-index: 1;

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.6);
      }
    }

    .textbox {
      font-size: 16px;

      h5 {
        margin: 0;
        text-align: center;
      }

      .yuantext {
        font-family: 'Arial', sans-serif;
        line-height: 1.6;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }

      .translatetext {
        margin-bottom: 0.8vw;
      }
    }
  }

  .youtubebox {
    margin-left: 2vw;
    margin-bottom: 20px;
  }

  .markdown-content-no {
    font-family: Outfit;
    color: #e1e1e1;
    // background: rgb(42, 36, 65);
    page-break-inside: avoid;
    padding: 0 2vw;
    margin-top: 40px;
  }

  .markdown-content {
    font-family: Outfit;
    color: #e1e1e1;
    // background: rgb(42, 36, 65);
    page-break-inside: avoid;
    padding: 0 2vw;
    max-height: calc(100vh - 175px);
    overflow: auto;
    margin-top: 40px;

    h1 {
      margin: 0 !important;
    }

    table {
      // width: 100%;
      border-collapse: collapse;
      font-family: Arial, sans-serif;
      font-size: 16px;
      margin: 20px 0;
      text-align: left;

    }

    th,
    td {
      padding: 8px 12px;
      text-align: left;
      border: 1px solid #ddd;
    }

    th {
      background-color: #97929253;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1em;
      font-weight: 600;
    }

    td {
      font-size: 0.9em;
      line-height: 1.5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
    }

    h1 {
      margin: 0;
      font-size: 2em;
      border-bottom: 1px solid #eaecef;
      padding-bottom: 0.3em;
    }

    h2 {
      font-size: 1.5em;
      border-bottom: 1px solid #eaecef;
      padding-bottom: 0.3em;
    }

    h3 {
      font-size: 1.25em;
    }

    h4 {
      font-size: 1em;
    }

    h5 {
      font-size: 0.875em;
    }

    h6 {
      font-size: 0.85em;
      color: #6a737d;
    }

    p {
      margin-top: 0;
      margin-bottom: 16px;
    }

    a {
      color: #0366d6;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    code {
      padding: 0.2em 0.4em;
      margin: 0;
      font-size: 85%;
      background-color: rgba(27, 31, 35, 0.05);
      border-radius: 3px;
    }

    pre {
      padding: 12px;
      overflow: auto;
      line-height: 1.45;
      background-color: #111111;
      border-radius: 3px;
    }

    blockquote {
      padding: 0 1em;
      color: #c7ccd3;
      border-left: 0.25em solid #dfe2e5;
      margin: 0 0 16px 0;
    }

    ul,
    ol {
      padding-left: 2em;
      margin-top: 0;
      margin-bottom: 16px;
    }

    img {
      max-width: 100%;
      box-sizing: content-box;
    }
  }
}
</style>
