import { render, staticRenderFns } from "./translate_toggle.vue?vue&type=template&id=e397ad5e&scoped=true"
import script from "./translate_toggle.vue?vue&type=script&lang=js"
export * from "./translate_toggle.vue?vue&type=script&lang=js"
import style0 from "./translate_toggle.vue?vue&type=style&index=0&id=e397ad5e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e397ad5e",
  null
  
)

export default component.exports