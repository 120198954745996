<template>
  <div class="pricing-container">
    <!-- 广告弹窗 -->
    <ad-dialog
      :visible.sync="showAdDialog"
      :image-url="guanggao"
      image-alt="Year End Deal"
      @close="handleAdClose"
    />

    <div class="free" v-if="false">
      <h1>Limited time free access to all features.</h1>
    </div>
    <div v-if="true">
      <h1>
        <!-- Get <span>Unlimited</span> audio recordings, notes, PDFs and flashcards -->
        Improve your learning efficiency by <span>10x</span> !
      </h1>
      <div class="billing-toggle" v-if="false">
        <button :class="{ active: billing === 'yearly' }" @click="billing = 'yearly'">
          Yearly
        </button>
        <button :class="{ active: billing === 'monthly' }" @click="billing = 'monthly'">
          Monthly
        </button>
      </div>
      <div class="plans">
        <div class="plan" v-for="plan in plans" :key="plan.name" :class="{ highlight: plan.name === 'Annual' }">
          <!-- <div class="badge" v-if="plan.name === 'Annual'">Save 60%</div> -->
          <!-- <div class="badge" v-if="plan.name === 'Annual'">+30 <br> Extra Days</div> -->
          <h2>{{ plan.name }}</h2>
          <div class="price">
            <span v-if="plan.price['dingyue'] != 'Free'">
              <span v-if="plan.name == 'Annual'">
                <!-- <span class="oldprice">${{ plan.price['monthly'] }}</span> -->
                ${{ plan.price["dingyue"] }}
              </span>
              <span v-else class="feature-highlight">${{ plan.price["dingyue"] }}</span>
            </span>
            <span v-if="plan.price[billing] !== 'Free'">Per Month</span>
            <span v-else>Free</span>
          </div>
          <div v-if="false" :class="{ billing: true, billinghl: plan.name === 'Unlimited' }">
            Billed {{ billing === "yearly" ? "Yearly" : "Monthly" }}
          </div>
          <ul>
            <li v-for="(feature, i) in plan.features" :key="i"
              :class="{ 'feature-highlight': plan.name !== 'Starter' && [0].includes(i) }">
              <span class="checkmark"></span> {{ feature }}
            </li>
          </ul>
          <button v-if="plan.price['dingyue'] == 'Free'" :class="{
            subscribe: true,
            subhighlight: plan.name === 'Unlimited',
          }" @click="gohomepage()">
            <span>Use Now</span>
          </button>
          <button v-else :class="{
            subscribe: true,
            subhighlight: plan.name === 'Unlimited',
          }" @click="subscribe(plan)">
            <span>Subscribe Now</span>
          </button>
          <!-- <button @click="createwechatpay(plan)"> wechatpay </button> -->
        </div>
      </div>
    </div>
    <!-- 添加支付方式选择窗 -->
    <el-dialog title="Select Payment Method" :visible.sync="showPaymentDialog" width="30vw" center>
      <div class="payment-methods">
        <div class="payment-button wechat" @click="handleWechatPay">
          <span>WeChat Pay</span>
          <img :src="icon_wechatpay" alt="WeChat Pay">
          <!-- <img :src="icon_alipay" alt="WeChat Pay"> -->
        </div>
        <div class="payment-button credit-card" @click="handleCreditCard">
          <span>Credit Card</span>
          <div class="card-icons">
            <img :src="icon_visa" alt="">
            <img :src="icon_wanshida" alt="">
            <img :src="icon_us_yt" alt="">
          </div>

        </div>
      </div>
    </el-dialog>

    <!-- 添加微信支付二维码弹窗 -->
    <el-dialog title="Scan the pay with WeChat" :visible.sync="showQRCodeDialog" width="30vw" center
      @closed="handleDialogClose">
      <div class="qrcode-container">
        <div class="qrcode-img">
          <span class="order-number">Order Number：{{ orderNumber }}</span>
          <div class="qrcode-wrapper" v-loading="isLoading" element-loading-text="Loading..."
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            <img v-if="!isLoading" :src="qrCodeUrl" alt="WeChat Pay QR Code">
            <!-- 添加遮罩层 -->
            <div v-if="isQrCodeExpired" class="qrcode-overlay" @click="refreshQRCode">
              <span>
                <i class="el-icon-refresh"></i>
                QR Code Expired
                <br>
                Click to refresh
              </span>
            </div>
          </div>
          <span>EasyNote AI-{{ currentPlan?.name }} Plan</span>
          <span>
            <span class="price-text">$100</span> <span>Per Year</span>
          </span>
        </div>
        <div class="order-info" v-if="false">
          <span>Order Number：{{ orderNumber }}</span>
          <span>EasyNote AI-{{ currentPlan?.name }} Plan</span>
          <span>{{ currentPlan?.price?.dingyue }} Per Month</span>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import AdDialog from '@/views/pages/index_page/popups/AdDlalog.vue'

export default {
  components: {
    AdDialog
  },

  data() {
    return {
      guanggao: require('@/assets/images/guanggao.png'),
      icon_wechatpay: require('@/assets/images/pay/icons8_wechatpay.png'),
      icon_alipay: require('@/assets/images/pay/icons8_alipay.png'),
      icon_visa: require('@/assets/images/pay/icons8_visa.png'),
      icon_wanshida: require('@/assets/images/pay/icons8_wanshida.png'),
      icon_us_yt: require('@/assets/images/pay/icons8_us_yt.png'),

      billing: "yearly",
      plans: [
        {
          name: "Starter",
          price: { yearly: "Free", monthly: "Free", dingyue: "Free" },
          features: [
            "2 Hours of Lecture Time Per Month",
            "3 PDF Upload Per Month",
            "10 chat messages Per Month",
            "Unlimited flashcards Per Month",
            "Unlimited Quiz Questions Per Month",
          ],
        },
        {
          name: "Annual",
          interval: "year",
          price: { yearly: "3.99", monthly: "8.39", dingyue: "8.39" },
          features: [
            "Unlimited Hours Lecture Time Per Month",
            "Unlimited PDF Uploads Per Month",
            "Unlimited chat messages Per Month",
            "Unlimited flashcards Per Month",
            "Unlimited Quiz Questions Per Month",
          ],
        },
        {
          name: "Monthly",
          interval: "month",
          price: { yearly: "8.99", monthly: "12.99", dingyue: "19.99" },
          features: [
            "Unlimited Hours Lecture Time Per Month",
            "Unlimited PDF Uploads Per Month",
            "Unlimited chat messages Per Month",
            "Unlimited flashcards Per Month",
            "Unlimited Quiz Questions Per Month",
          ],
        },
      ],
      showPaymentDialog: false,
      showQRCodeDialog: false,
      qrCodeUrl: '',
      currentPlan: null,

      orderNumber: 123456,
      expireTime: 0,
      checkExpireTimer: null,
      statusPollingTimer: null,
      isQrCodeExpired: false,
      isLoading: false,
      showAdDialog: false,
    };
  },
  mounted() {
    this.getpaln();
    // this.checkAndShowAd();
  },


  methods: {
    checkAndShowAd() {
      // 检查cookie中是否有广告展示记录
      const hasSeenAd = this.$cookies.get('has_seen_year_end_deal');
      if (!hasSeenAd) {
        // 如果没有展示记录，显示广告
        this.showAdDialog = true;
        // 设置cookie，有效期30天
        this.$cookies.set('has_seen_year_end_deal', 'true', '30d');
      }
    },
    async handleWechatPay() {
      this.showPaymentDialog = false;
      this.showQRCodeDialog = true;
      this.isLoading = true; // 设置 loading 状态

      const plan = this.currentPlan;
      const { price: { dingyue: unitamount }, interval, name: payname } = plan;

      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/createalphapaysession/", {
          subid,
          unitamount,
          interval,
          payname,
        });

        const { code, qr_image, order_number, expire_time } = response.data;

        if (code === 301) {
          this.$message.error("You have already subscribed");
          this.$router.push("/home");
          return;
        }

        if (code === 200) {
          this.qrCodeUrl = qr_image;
          this.orderNumber = order_number;
          this.expireTime = expire_time;

          // 开始轮询订单状态
          this.startPollingOrderStatus();

          // 设置二维码过期检查
          clearInterval(this.checkExpireTimer);
          this.checkExpireTimer = setInterval(() => {
            if (Date.now() / 1000 >= this.expireTime) {
              this.isQrCodeExpired = true; // 设置过期状态而不是清空URL
              clearInterval(this.checkExpireTimer);
              clearInterval(this.statusPollingTimer);
            }
          }, 1000);
        } else {
          this.$message.error(response.data.message);
        }
      } catch (error) {
        console.error("Error:", error);
        this.$message.error("处理请求时出错，请重试");
      } finally {
        this.isLoading = false; // 结束 loading 状态
      }
    },

    async refreshQRCode() {
      this.isQrCodeExpired = false;
      this.isLoading = true; // 设置 loading 状态

      try {
        const response = await this.$axios.post("/api/refresh_qr_code/", {
          order_number: this.orderNumber
        });

        const { code, qr_image, order_number, expire_time } = response.data;
        if (code === 200) {
          this.qrCodeUrl = qr_image;
          this.orderNumber = order_number;
          this.expireTime = expire_time;

          // 重新开始轮询
          this.startPollingOrderStatus();

          // 设置二维码过期检查
          clearInterval(this.checkExpireTimer);
          this.checkExpireTimer = setInterval(() => {
            if (Date.now() / 1000 >= this.expireTime) {
              this.isQrCodeExpired = true; // 设置过期状态而不是清空URL
              clearInterval(this.checkExpireTimer);
              clearInterval(this.statusPollingTimer);
            }
          }, 1000);
        } else {
          this.$message.error(response.data.message);
        }
      } catch (error) {
        this.isQrCodeExpired = true; // 如果刷新失败，显示过期状态
        console.error("Error:", error);
        this.$message.error("刷新二维码失败");
      } finally {
        this.isLoading = false; // 结束 loading 状态
      }
    },

    startPollingOrderStatus() {
      // 清除之前的轮询
      if (this.statusPollingTimer) {
        clearInterval(this.statusPollingTimer);
      }

      // 开始新的轮询
      this.statusPollingTimer = setInterval(async () => {
        try {
          const response = await this.$axios.post("/api/query_order_status/", {
            order_number: this.orderNumber
          });

          if (response.data.code === 200) {
            // 支付成功
            clearInterval(this.statusPollingTimer);
            this.showQRCodeDialog = false;
            this.$message.success("支付成功!");
            this.$router.push("/home");
          }
        } catch (error) {
          console.error("Error polling status:", error);
        }
      }, 3000); // 每3秒查询一次
    },

    beforeDestroy() {
      // 组件销毁时清除定时器
      if (this.checkExpireTimer) {
        clearInterval(this.checkExpireTimer);
      }
      if (this.statusPollingTimer) {
        clearInterval(this.statusPollingTimer);
      }
    },

    async getpaln() {
      try {
        const res = await this.$axios.post("/api/getpricedata/");
        const redata = res.data;
        this.plans = redata.data;
      } catch (error) {
        console.error(error);
      }
    },
    gohomepage() {
      this.$router.push("/home");
    },
    redirectToPayment() {
      // Stripe Payment Link
      const paymentLink = "https://buy.stripe.com/test_7sIdR2asD7398RG288";
      // 使用浏览器打开支付链接
      window.location.href = paymentLink;
    },
    subscribe(plan) {
      this.currentPlan = plan;
      if (plan.name === 'Annual') {
        this.showPaymentDialog = true;
      } else {
        this.handleCreditCard();
      }
      // this.handleCreditCard();
    },
    async handleCreditCard() {
      this.showPaymentDialog = false;
      const plan = this.currentPlan;
      const { price: { dingyue: unitamount }, interval, name: payname } = plan;

      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/createcheckoutsession/", {
          subid,
          unitamount,
          interval,
          payname,
        });

        const { url, code } = response.data;
        switch (code) {
          case 200:
            window.location.href = url;
            break;
          case 201:
            this.$message({
              message: "Please log in",
              type: "error",
            });
            this.$router.push("/login");
            break;
          case 301:
            this.$message({
              message: "You have already subscribed",
              type: "success",
            });
            this.$router.push("/home");
            break;
          default:
            this.$message({
              message: "Error creating checkout session",
              type: "error",
            });
        }
      } catch (error) {
        console.error("Error:", error);
        this.$message({
          message: "处理请求时出错，请重试。",
          type: "error",
        });
      }
    },
    handleDialogClose() {
      // 清理所有定时器
      if (this.checkExpireTimer) {
        clearInterval(this.checkExpireTimer);
        this.checkExpireTimer = null;
      }
      if (this.statusPollingTimer) {
        clearInterval(this.statusPollingTimer);
        this.statusPollingTimer = null;
      }
      // 重置相关状态
      this.isQrCodeExpired = false;
      this.isLoading = false;
      this.qrCodeUrl = '';
    },
    handleAdClose() {
      this.showAdDialog = false;
    }
  }
};
</script>

<style lang="less" scoped>
.pricing-container {
  border: 1px solid #4e5164;
  border-left: none;
  border-right: none;
  color: white;
  font-family: Arial, sans-serif;
  padding: 50px 0px;
  text-align: center;

  .free {
    height: 500px;

    h1 {
      margin-top: 200px;
    }
  }

  h1 {
    font-size: 2vw;

    span {
      color: rgb(255, 87, 51);
    }
  }

  .billing-toggle {
    margin: 30px 0px;

    button {
      background-color: transparent;
      border: 1px solid #444;
      color: white;
      padding: 10px 20px;
      cursor: pointer;
      transition: all 0.3s;

      &.active {
        background-color: #7c3aed;
        border-color: #7c3aed;
      }

      &:first-child {
        border-radius: 20px 0 0 20px;
      }

      &:last-child {
        border-radius: 0 20px 20px 0;
      }
    }
  }

  .plans {
    display: flex;
    justify-content: center;
    gap: 30px;

    .feature-highlight {
      color: #00F9E5;
    }

    .plan {
      padding: 0px 20px 100px 0px;
      text-align: left;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.1);
      margin: 30px 0;
      position: relative;
      // height: 400px;
      .subscribe {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
        border: none;
        background: rgb(108, 92, 231);
        color: white;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        padding: 10px 0;
        width: 80%;
      }

      .subhighlight {
        background-color: white;
        border: none;
        color: #000;
      }

      h2 {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
      }

      .h2red {
        color: #ff5733;
      }

      .price {
        font-weight: bold;
        text-align: center;
        padding: 7px 0px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;
        margin: 0px auto;
        border: 1px solid #ddd;

        .oldprice {
          text-decoration: line-through;
          color: #000;

        }

        span {
          margin-right: 5px;
          font-size: 19px;
          font-weight: normal;
        }
      }

      &.highlight {
        // box-shadow: 0px 0px 20px 4px rgb(255, 255, 255);
        // transform: scale(1.05);
        overflow: hidden;
        background-color: #6C5CE7;

        .badge {
          position: absolute;
          top: -15px;
          right: -65px;
          background-color: #FF0000;
          color: white;
          padding: 40px 5px 10px;
          width: 160px;
          text-align: center;
          font-size: 15px;
          font-weight: 500;
          transform: rotate(45deg);
          font-weight: bold;
        }

        .subscribe {
          background-color: #fff;
          color: #6C5CE7;
        }
      }

      .billing {
        color: #888;
        font-size: 14px;
        margin: 15px 0px;
        text-align: center;
      }

      .billinghl {
        color: white;
      }

      ul {
        text-align: left;
        li {
          font-size: 14px;
          font-weight: bold;
          margin: 12px 0;

          .checkmark {
            color: #ddd;
            margin-right: 5px;
            font-size: 16px;
            font-weight: 900;
          }
        }
      }
    }
  }

  .payment-methods {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    .payment-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 20px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s;
      font-size: 14px;
      font-weight: bold;

      span {
        font-size: 16px;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }

      &.wechat {
        background-color: #2DC100;
        color: white;
      }

      &.credit-card {
        background-color: #6C5CE7;
        color: white;
      }

      img {
        height: 35px;
        width: 35px;
      }

      .card-icons {
        display: flex;
        gap: 10px;


      }
    }
  }

  .qrcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;

    .qrcode-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .order-number {
        color: #ddd;
      }

      img {
        border: 1px solid #fefefe;
        width: 200px;
        height: 200px;
        margin-top: 20px;
      }

      span {
        margin-top: 10px;
      }

      .refresh-link {
        cursor: pointer;
        color: #00F9E5;
      }

      .price-text {
        font-size: 26px;
        font-weight: bold;
        color: red;
      }

      .qrcode-wrapper {
        position: relative;
        display: inline-block;
        margin: 20px 0;
        width: 200px; // 添加固定宽度
        height: 200px; // 添加固定高度

        img {
          max-width: 100%;
          max-height: 100%;
          margin: 10px 0;
        }

        .qrcode-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 205px;
          height: 220px;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: background-color 0.3s;

          span {
            color: white;
            text-align: center;
            font-size: 14px;
            line-height: 1.5;

            i {
              font-size: 24px;
              margin-bottom: 8px;
              display: block;
            }
          }

          &:hover {
            background: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }

    .order-info {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}

/deep/ .el-dialog {
  background-color: #38354A;
  width: 400px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;

  .el-dialog__title {
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  .el-dialog__header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.ad-dialog {
  :deep(.el-dialog) {
    background: transparent;
    box-shadow: none;
  }

  :deep(.el-dialog__header) {
    display: none;
  }
  
  :deep(.el-dialog__body) {
    padding: 0;
    margin: 0;
  }
}

.ad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.ad-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.close-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
